<template>
    <div>
        <b-card title="Podatki stranke" v-if="client">
            <validation-observer ref="validation">
                <div class="row">

                    <b-form-group class="col-12 col-md-6" label="Naziv">
                        <validation-provider #default="{ errors }" name="Naziv" rules="required">
                            <b-form-input v-model="client.name"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group class="col-12 col-md-6" label="Alias">
                        <validation-provider #default="{ errors }" name="Alias" rules="required">
                            <b-form-input v-model="client.alias"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group class="col-12 col-md-6" label="Naslov">
                        <validation-provider #default="{ errors }" name="Naslov" rules="required">
                            <b-form-input v-model="client.address"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group class="col-12 col-md-6" label="Davčna številka">
                        <validation-provider #default="{ errors }" name="tax_number" rules="required|numeric|min:8|max:10">
                            <b-form-input v-model="client.tax_number"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group class="col-12 col-md-6" label="Zaključek dneva">
                        <validation-provider #default="{ errors }" name="closing_time" rules="required">
                            <b-form-timepicker v-model="client.closing_time"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group class="col-12 col-md-6" label="Prodajalec">
                        <validation-provider #default="{ errors }" name="prodajalec">
                            <v-select v-model="client.reseller_id" label="name" :reduce="x => x.id" :clearable="true" :options="resellers"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group class="col-12" label="Davčni zavezanec">
                        <b-form-checkbox switch v-model="client.is_tax_payer"/>
                    </b-form-group>

                    <b-form-group class="col-12" label="Onemogočen">
                        <b-form-checkbox switch v-model="client.disabled"/>
                    </b-form-group>

                    <b-form-group class="col-12" label="Verzija">
                        <validation-provider #default="{ errors }"  name="tier" rules="required">
                            <v-select v-model="client.tier" :reduce="ele => ele.id" :clearable="false" :options="tiers"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>

                </div>
            </validation-observer>

            <div class="d-flex w-full justify-content-end">
                <b-button variant="primary" @click="editClient">
                    Potrdi spremembe
                </b-button>
            </div>

        </b-card>

        <b-card title="Uporabniki">

            <div class="d-flex w-full justify-content-end">
                <b-button variant="primary" @click="addUserToClient">
                    Dodaj uporabnika na stranko
                </b-button>
            </div>

            <basic-table :columns="columns" :data="users" v-slot="props" :enableRowMarking="true">
                <span v-if="props.column.displayType === 2" class="d-flex justify-content-center">
                    <template v-if="props.row.status === InvitationStatus.Confirmed">
                        <b-button variant="warning" class="mr-1" @click="editUser(props.row.connection)"><feather-icon icon="EditIcon"/></b-button>
                        <b-button variant="danger" class="mr-0" @click="deleteUser(props.row.id)"><feather-icon icon="TrashIcon"/></b-button>
                    </template>
                    <template v-else-if="props.row.status === InvitationStatus.Expired">
                        <b-button variant="secondary" class="mr-1" @click="resend(props.row.id,props.row)">Ponovno pošlji</b-button>
                        <b-button variant="danger" class="mr-0" @click="deleteInvitation(props.row.email)"><feather-icon icon="TrashIcon"/></b-button>
                    </template>
                </span>
                <span v-else-if="props.column.displayType === 1" class="d-flex justify-content-center">
                    {{props.row.name === '' ? '/' :props.row.name}}
                </span>
                <span v-else-if="props.column.displayType === 3" class="d-flex justify-content-center">
                    <div v-for="item in invitationStatuses" :key="item.id">
                        <b-badge :variant="item.variant" class="w-100" v-if="item.id === props.row.status">
                            {{item.label}}
                        </b-badge>
                    </div>

                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </basic-table>
        </b-card>
        <b-modal id="tiersModal" title="Opozorilo">
            {{this.$t('general.cant_switch_tiers')}}
            <template #modal-footer>
                <div class="d-flex justify-content-end">
                    <div>
                        <b-button @click="hide" variant="warning">
                            V redu
                        </b-button>
                    </div>
                </div>
            </template>
        </b-modal>
        <AddUser ref="addUserToClientModal" @added="loadData" :client_id="client_id" :already_added_users="users"></AddUser>
    </div>
</template>

<script>

    import {
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BFormTimepicker,
        BCard, BButton, BBadge
    } from 'bootstrap-vue'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import BasicTable from '@/views/components/BasicTable.vue'
    import AddUser from '@/views/Clients/AddUser.vue'
    import {Tiers} from '@/libs/enums/Tiers'
    import vSelect from 'vue-select'
    import {InvitationStatus} from '@/libs/enums/InvitationStatus'

    export default {
        computed: {
            InvitationStatus() {
                return InvitationStatus
            }
        },
        components: {
            BBadge,
            AddUser,
            BButton,
            BasicTable,
            BFormGroup,
            vSelect,
            ValidationProvider,
            ValidationObserver,
            BFormTimepicker,
            BFormCheckbox,
            BFormInput,
            BCard
        },
        data() {
            return {
                client_id: '',
                client: undefined,
                originalTier: undefined,
                users: [],
                resellers: [],
                invitationStatuses: [
                    {
                        label: 'Potrjen',
                        id: InvitationStatus.Confirmed,
                        variant:'success'
                    },
                    {
                        label: 'V teku',
                        id: InvitationStatus.ConfirmationInProgress,
                        variant:'info'
                    },
                    {
                        label: 'Potečen',
                        id: InvitationStatus.Expired,
                        variant:'warning'
                    }
                ],
                tiers: [
                    {
                        label: 'Essential',
                        id: Tiers.Essential
                    },
                    {
                        label: 'Standard',
                        id: Tiers.Standard
                    },
                    {
                        label: 'Premium',
                        id: Tiers.Premium
                    }
                ],
                columns: [
                    {
                        label: 'Ime',
                        field: 'name',
                        displayType: 1,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Email',
                        field: 'email',
                        displayType: 0,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Status',
                        field: 'status',
                        displayType: 3,
                        filterOptions: {
                            enabled: false
                        }
                    },
                    {
                        label: 'Možnosti',
                        field: 'options',
                        displayType: 2,
                        filterOptions: {
                            enabled: false
                        },
                        sortable: false
                    }
                ]

            }
        },
        methods: {
            async resend(id, user) {
                try {
                    this.loading = true
                    await this.$http.post(`/api/admin/v1/client/resend/${this.client_id}`, user)
                    this.$printSuccess('Ponovno poslan email!')
                    await this.loadData()
                } catch (err) {
                    this.$printError(err)
                } finally {
                    this.loading = false
                }
            },
            async deleteInvitation(email) {
                try {
                    const confirmDelete = await this.$confirmDialog('Ali res želite izbrisati vrstico?')
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/admin/v1/client/${this.client_id}/invitation/${email}`)
                    this.$printSuccess('Brisanje je bilo uspešno!')
                    await this.loadData()
                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)

                }
            },
            editUser(connection) {
                this.$refs.addUserToClientModal.open(true, connection)
            },
            addUserToClient() {
                this.$refs.addUserToClientModal.open(false, null)
            },
            async editClient() {
                try {
                    const timestampString = `1970-01-31T${this.client.closing_time}.000Z`
                    const timestamp = this.$dayjs(timestampString)

                    const tmpObject = {
                        name: this.client.name,
                        address: this.client.address,
                        alias: this.client.alias,
                        tax_number: Number(this.client.tax_number),
                        closing_time: timestamp,
                        is_tax_payer: this.client.is_tax_payer,
                        disabled: this.client.disabled,
                        reseller_id: this.client.reseller_id,
                        tier: this.client.tier
                    }

                    await this.$http.put(`/api/admin/v1/client/${this.client.id}`, tmpObject)
                    this.$printSuccess('Urejanje je bilo uspešno!')
                } catch (err) {
                    if (err && err.response && err.response.data && (err.response.data.error === 'can\'t switch tiers')) {
                        this.$bvModal.show('tiersModal')
                        this.client.tier = this.originalTier
                    } else {
                        this.$printError('Prišlo je do napake')
                    }
                }
            },
            hide() {
                this.$bvModal.hide('tiersModal')
            },
            async deleteUser(id) {
                try {
                    const confirmDelete = await this.$confirmDialog('Ali res želite izbrisati vrstico?')
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/admin/v1/client/${this.client_id}/${  id}`)
                    this.$printSuccess('Uporabnik uspešno izbrisan')
                    this.loadData()
                } catch (err) {
                    this.$printError(err)
                }
            },
            async loadData() {
                try {
                    const clientResponse = this.$http.get(`/api/admin/v1/client/${this.client_id}`)
                    const userResponse = this.$http.get(`/api/admin/v1/users/list/${this.client_id}`)
                    const resellerResponse = this.$http.get('/api/admin/v1/resellers/')


                    const responses = await Promise.all([clientResponse, userResponse, resellerResponse])
                    this.client = responses[0].data
                    this.users = responses[1].data ?? []
                    this.resellers = responses[2].data ?? []
                    this.originalTier = this.client.tier
                    this.client.closing_time = this.$dayjs(this.client.closing_time).utc().format('HH:mm')

                    if (this.client.reseller_id === '000000000000000000000000') this.client.reseller_id = ''
                } catch (err) {
                    this.$printError(err)
                }
            }
        },
        mounted() {
            this.client_id = this.$route.params.client_id

            this.loadData()
        }
    }
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
